import claimTypes from './claims.types';

export const getAllClaimsAction = (payload, cb, cbError) => ({
  type: claimTypes.GET_ALL_CLAIMS,
  payload,
  cb,
  cbError
});

export const getSingleClaimAction = (payload, cb, cbError) => ({
  type: claimTypes.GET_SINGLE_CLAIM_DETAILS,
  payload,
  cb,
  cbError
});

export const fileClaimsAction = (payload, cb, cbError) => ({
  type: claimTypes.FILE_CLAIMS,
  payload,
  cb,
  cbError
});

export const fileBulkClaimsAction = (payload, cb, cbError) => ({
  type: claimTypes.BULK_FILE_CLAIMS,
  payload,
  cb,
  cbError
});

export const makePaymentAction = (payload, cb, cbError) => ({
  type: claimTypes.MAKE_PAYMENT,
  payload,
  cb,
  cbError
});
