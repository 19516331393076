import { API } from 'const';
import { takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import queryFormatter from 'helpers/queryFormatter';
import partnerTypes from './partners.types';

function* createPartnerType(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.PARTNERSHIP_TYPE}`, data));
    if (res.status == 201) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerType(action) {
  try {
    const { cb } = action;

    const res = yield call(() => api.get(`${API.PARTNERSHIP_TYPE}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* createPartner(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.PARTNERS}`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message;
    cbError(alert);
  }
}

function* getAllPartners(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PARTNERS}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PARTNERS}/${data.id}/details`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerTransactionDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/${data.id}/transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerOutstandingTransactionDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/${data.id}/outstanding-transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* editPartnerDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.put(`${API.PARTNERS}/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* deletePartner(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.delete(`${API.PARTNERS}/${data}`));
    if (res.status === 200) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* updatePartnerStatus(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.patch(`${API.PARTNERS}/${data.id}/status`, data?.status));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* createPartnerPayment(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.PARTNERS}/payments`, data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message;
    cbError(alert);
  }
}
function* getPartnerPatientDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/${data.id}/patients?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* createInvoice(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.SETTING}/invoice`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getInvoiceDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.SETTING}/invoice/${data.visit_id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnerInvoices(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/${data.id}/invoices?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchPartnerSaga() {
  yield takeEvery(partnerTypes.CREATE_PARTNER, createPartnerType);
  yield takeEvery(partnerTypes.GET_PARTNERSHIP_TYPE, getPartnerType);
  yield takeEvery(partnerTypes.CREATE_PARTNERS, createPartner);
  yield takeEvery(partnerTypes.GET_ALL_PARTNERS, getAllPartners);
  yield takeEvery(partnerTypes.GET_PARTNER_DETAILS, getPartnerDetails);
  yield takeEvery(partnerTypes.EDIT_PARTNER_DETAILS, editPartnerDetails);
  yield takeEvery(partnerTypes.DELETE_PARTNER, deletePartner);
  yield takeEvery(partnerTypes.UPDATE_PARTNER_STATUS, updatePartnerStatus);
  yield takeEvery(partnerTypes.GET_PARTNER_TRANSACTION_DETAILS, getPartnerTransactionDetails);
  yield takeEvery(
    partnerTypes.GET_PARTNER_OUTSTANDING_TRANSACTION_DETAILS,
    getPartnerOutstandingTransactionDetails
  );
  yield takeEvery(partnerTypes.CREATE_PARTNERS_PAYMENT, createPartnerPayment);
  yield takeEvery(partnerTypes.GET_PARTNER_PATIENT_DETAILS, getPartnerPatientDetails);
  yield takeEvery(partnerTypes.CREATE_INVOICE, createInvoice);
  yield takeEvery(partnerTypes.GET_INVOICE_DETAILS, getInvoiceDetails);
  yield takeEvery(partnerTypes.GET_PARTNER_INVOICES, getPartnerInvoices);
}

export default watchPartnerSaga;
