/* eslint-disable */
export const headers = [
  {
    text: 'Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Admin Name',
    alignment: 'center',
    sortable: false,
    key: 'admin_Name'
  },
  {
    text: 'Location',
    alignment: 'center',
    sortable: false,
    key: 'location'
  },
  {
    text: 'No. of Patients',
    alignment: 'center',
    sortable: false,
    key: 'no_of_patients'
  },
  {
    text: 'Center type',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date created',
    alignment: 'center',
    sortable: false,
    key: 'date_created'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'action'
  }
];

export const data = [
  {
    id: 1,
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    id: 2,
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Abuja',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Spike',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Spike',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Abuja',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    name: 'Beacon diagnostics',
    admin_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  }
];

export const patientHeader = [
  {
    text: 'Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Email Address',
    alignment: 'center',
    sortable: false,
    key: 'email'
  },
  {
    text: 'Phone no',
    alignment: 'center',
    sortable: false,
    key: 'phone'
  },
  {
    text: 'Gender',
    alignment: 'center',
    sortable: false,
    key: 'gender'
  },
  {
    text: 'Centers',
    alignment: 'center',
    sortable: false,
    key: 'gender'
  },
  {
    text: 'Date Created',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },

  {
    text: '',
    alignment: '',
    sortable: false,
    key: 'name'
  }
];

export const patientData = [
  {
    id: 1,
    name: 'Cameron Williamson',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 2,
    name: 'Floyd Miles',
    email: 'floydmiles@gmail.com',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 3,
    name: 'Jane Cooper',
    email: 'cameronwilliamson@gm...',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 4,
    name: 'Ralph Edwards',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 5,
    name: 'Darrell Steward',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 6,
    name: 'Eleanor Pena',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 7,
    name: 'Savannah Nguyen',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 8,
    name: 'Leslie Alexander',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  },
  {
    id: 9,
    name: 'Esther Howard',
    email: 'cameronwilliamson@gm',
    date_created: 'May 27, 2022',
    type: 'Center type G',
    gender: 'Male',
    center: 'Spike',
    phone: '+234 703 3218 457'
  }
];

export const adminHeader = [
  {
    text: 'Name',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Email Address',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'email'
  },
  {
    text: 'Centre',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Role',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'role'
  },

  {
    text: 'Status',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date Created',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },
  {
    text: '',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'name'
  }
];

export const cliniciansHeaders = [
  {
    text: 'Name',
    alignment: 'name',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Email Address',
    alignment: 'email',
    sortable: false,
    key: 'email'
  },
  {
    text: 'Centre',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Room',
    alignment: 'room',
    sortable: false,
    key: 'room'
  },
  {
    text: 'Status',
    alignment: 'status',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date Created',
    alignment: 'date',
    sortable: false,
    key: 'date'
  },
  {
    text: '',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'name'
  }
];

export const beamPartnersHeader = [
  {
    text: 'Full Name',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Email address',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Partners type',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Partners name',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Status',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Date created',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: '',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'name'
  }
];
export const beamUsersHeader = [
  {
    text: 'Full Name',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Email address',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Partners type',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Partners name',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Status',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Date created',
    alignment: 'center',
    alignment: 'center',
    sortable: false,
    key: 'center'
  }
];
export const adminTableData = [
  {
    id: 1,
    name: 'Cameron Williamson',
    email: 'beacon@gmail.com',
    role: 'staff',
    location: 'South Africa',
    status: 'active',
    date_created: 'May 27, 2022'
  },

  {
    id: 2,
    name: 'Esther Howard',
    email: 'beacon@gmail.com',
    role: 'Mdass HQ',
    location: 'South Africa',
    status: 'active',
    date_created: 'May 27, 2022'
  },
  {
    id: 3,
    name: 'Brooklyn Simmons',
    email: 'beacon@gmail.com',
    role: 'Analyst',
    location: 'Monaco',
    status: 'active',
    date_created: 'May 27, 2022'
  },
  {
    id: 4,
    name: 'Cameron Williamson',
    email: 'beacon@gmail.com',
    role: 'Financial Officer',
    location: 'Viet Nam',
    status: 'active',
    date_created: 'May 27, 2022'
  },
  {
    id: 5,
    name: 'Savannah Nguyen',
    email: 'beacon@gmail.com',
    role: 'Financial Officer',
    location: 'Poland',
    status: 'active',
    date_created: 'May 27, 2022'
  },
  {
    id: 6,
    name: 'Ralph Edwards',
    email: 'beacon@gmail.com',
    role: 'MDaas HQ',
    location: 'Georgia',
    status: 'active',
    date_created: 'May 27, 2022'
  },
  {
    id: 7,
    name: 'Darlene Robertson',
    email: 'beacon@gmail.com',
    role: 'MDaas HQ',
    location: 'Pakistan',
    status: 'active',
    date_created: 'May 27, 2022'
  }
];

export const beamPartnerData = [
  {
    id: 1,
    fullname: 'Ralph Edwards',
    email: 'beacon@gmail.com',
    partners_type: 'MDaas HQ',
    partners_name: 'Georgia',
    status: 'active',
    date_created: 'May 27, 2022'
  }
];

export const websiteHeader = [
  {
    text: 'Display Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Center Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Location',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Center Type',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'name'
  }
];
export const websiteData = [
  {
    id: 1,
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    center_type: 'Embedded'
  },
  {
    id: 2,
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Abuja',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Embedded',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Spike',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Spike',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Abuja',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  },
  {
    display_name: 'Beacon diagnostics',
    center_Name: 'Kingsley Omin',
    location: 'Lagos',
    no_of_patients: 30,
    center_type: 'Full Service',
    date_created: 'May 27, 2022',
    time: '12:38 PM'
  }
];

export const invoicesHeader = [
  {
    text: 'Invoice No.',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Organisation name',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Centre',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Amount remaining',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Amount paid',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Date generated',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Date paid',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: 'Status',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  },
  {
    text: ' ',
    alignment: 'invoice',
    sortable: false,
    key: 'invoice'
  }
];

export const invoicesData = [
  {
    id: 1,
    invoice_no: 'INV10',
    name: 'Avon Products',
    centre: 'Lekki HQ',
    amount: '₦650,000',
    date_generated: '8 Sep, 2020',
    date_paid: '06 Jun, 2024',
    status: 'paid'
  },
  {
    id: 2,
    invoice_no: 'INV10',
    name: 'Avon Products',
    centre: 'Lekki HQ',
    amount: '₦650,000',
    date_generated: '8 Sep, 2020',
    date_paid: '06 Jun, 2024',
    status: 'partially_paid'
  },
];
