import React, { useEffect, useState } from 'react';
import SearchBox from 'components/SearchBox/SearchBox';
import Button from 'components/Button/Button';
import Table from 'components/Table';
import Pagination from 'components/Pagination/Pagination';
import styles from './invoices.module.scss';
import { invoicesHeader } from 'mocks/table';
import placeholder from 'assets/icons/table-placeholder.svg';
import FilterIcon from 'assets/icons/filterIcon.svg';
import Cancel from 'assets/icons/close.svg';
import InvoicesFilter from './invoiceFilter';
import InvoicesRow from './invoicesRow';
import Dropdown from 'components/Dropdown/Dropdown';
import Dots from 'assets/icons/vertical-dot.svg';
import { Option } from 'components/Dropdown/Option';
import Indicator from 'components/indicator';
import { useDispatch } from 'react-redux';
import { getAllInvoicesActions } from 're-ducks/invoices';
import { useDebounce } from 'use-debounce';
import FormatDate from 'helpers/formatDate';
import getTotalPages from 'helpers/getTotalPages';
import MakePayment from 'container/partners/MakePayment';
import { Invoice } from 'container/partners/Invoice/Invoice';
import { getInvoiceDetailsAction, getPartnerPatientDetailsAction } from 're-ducks/partner';
import { toast } from 'react-toastify';
import useActivePermission from 'hooks/useActivePermissions';

const EmptyState = () => {
  return (
    <div className={styles.EmptyState}>
      <img src={placeholder} alt="Empty state placeholder" />
      <p>No invoice yet.</p>
    </div>
  );
};

const Invoices = () => {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(location?.state ? location?.state?.page : 1);
  const [perPage, setPerPage] = useState(50);
  const [searchDebounce] = useDebounce(searchValue, 300);
  const [allInvoices, setAllInvoices] = useState([]);
  const [makePayment, setMakePayment] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [viewType, setViewType] = useState('long');
  const [partnerInformation, setPartnerInformation] = useState({});
  const canViewInvoice = useActivePermission('invoice', 'view');

  const handlePageChange = (num) => {
    setPage(num.selected + 1);
  };

  const handleInvoice = () => {
    setLoading(true);
    dispatch(
      getAllInvoicesActions(
        {
          params: {
            page: page,
            per_page: perPage,
            search: searchDebounce,
            ...filteredData
          }
        },
        (res) => {
          setAllInvoices(res);
          setLoading(false);
          setPerPage(50);
        }
      )
    );
  };
  useEffect(() => {
    handleInvoice();
  }, [page, searchDebounce, filteredData]);

  const PartnerPatientDetails = (row) => {
    dispatch(
      getPartnerPatientDetailsAction(
        {
          id: row.paying_partner_id
        },
        (res) => {
          setPartnerInformation(res?.partnerDetails);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  const handleMakePayment = (row) => {
    setMakePayment(true);
    setInvoiceData(row);
    PartnerPatientDetails(row);
  };

  const onViewInvoice = (type, row) => {
    setLoading(true);
    dispatch(
      getInvoiceDetailsAction(
        {
          visit_id: row?.visit_ids[0]
        },
        (res) => {
          setInvoiceData(res);
          setViewType(type);
          setLoading(false);
          setOpenInvoice(true);
          PartnerPatientDetails(row);
        },
        (err) => {
          toast.error(err);
        }
      )
    );
  };
  return (
    <section>
      <div>
        {' '}
        <p id="invoice" className={styles.counts}>
          {loading ? 0 : allInvoices?.total} Invoices
        </p>
      </div>
      <div className="actionBox">
        <div className={styles.actionBox__search}>
          <SearchBox
            placeholder="Search by name"
            onChange={(e) => {
              setSearchValue(e);
              setPage(1);
            }}
            searchValue={searchValue}
          />
          <Button theme="secondary" size="plain">
            <div>
              {Object.keys(filteredData || {}).length !== 0 ? (
                <div
                  className={styles.filterBtn}
                  aria-hidden
                  onClick={() => {
                    setFilteredData([]);
                    setOpenFilter(true);
                  }}>
                  <p>Filter</p> &nbsp;
                  <img src={Cancel} alt="cancel" />
                </div>
              ) : (
                <div className={styles.filterBtn} onClick={() => setOpenFilter(true)} aria-hidden>
                  <p>Filter</p> &nbsp;
                  <img src={FilterIcon} alt="filter" />
                </div>
              )}
            </div>
          </Button>
        </div>
      </div>

      <Table
        headers={invoicesHeader}
        tableData={allInvoices?.invoices}
        placeHolderImg={<EmptyState />}
        placeholderText="invoices"
        loading={loading}>
        {(row) => {
          return (
            <>
              <InvoicesRow row={row}>{row?.invoice_no || '-'}</InvoicesRow>
              <InvoicesRow row={row}>{row?.organization_name || '-'}</InvoicesRow>
              <InvoicesRow row={row}>{row?.center || '-'}</InvoicesRow>
              <InvoicesRow row={row}>{parseFloat(row?.amount).toFixed(2) || '-'}</InvoicesRow>
              <InvoicesRow row={row}>{parseFloat(row?.amount_paid).toFixed(2) || '-'}</InvoicesRow>
              <InvoicesRow row={row}>{FormatDate(row.date_generated || '-')}</InvoicesRow>
              <InvoicesRow row={row}>
                {row?.date_paid ? FormatDate(row?.date_paid) : '-'}
              </InvoicesRow>
              <InvoicesRow row={row}>
                <div className={styles.indicatorWrapper}>
                  <Indicator isActive={row?.payment_status} />
                  <span>{row?.payment_status || '-'}</span>
                </div>
              </InvoicesRow>
              <td>
                <Dropdown
                  content={
                    <div>
                      {canViewInvoice && (
                        <>
                          <Option onClick={() => onViewInvoice('short', row)}>
                            {' '}
                            Short Invoice View
                          </Option>
                          <Option onClick={() => onViewInvoice('long', row)}>
                            Long Invoice View
                          </Option>
                        </>
                      )}
                      {row?.payment_status !== 'paid' && (
                        <Option onClick={() => handleMakePayment(row)}>Make Payment</Option>
                      )}
                    </div>
                  }>
                  <img src={Dots} alt="dot" />
                </Dropdown>
              </td>
            </>
          );
        }}
      </Table>
      {!loading || allInvoices?.length > 1 ? (
        <Pagination
          totalPage={getTotalPages(allInvoices?.total, 50)}
          changeCurrentPage={handlePageChange}
          currentPage={page}
          handlePageInput={(e) => setPage(e)}
          forcePage={page - 1}
        />
      ) : (
        ''
      )}
      {openFilter && (
        <InvoicesFilter
          isOpen={openFilter}
          isClose={() => setOpenFilter(false)}
          setFilteredData={setFilteredData}
          setPage={setPage}
        />
      )}
      {makePayment && (
        <MakePayment
          openPayment={makePayment}
          setOpenPayment={setMakePayment}
          source={partnerInformation?.partnership_type}
          data={invoiceData}
          setLoading={setLoading}
          handleInvoice={handleInvoice}
          selectedData={allInvoices?.invoices}
        />
      )}
      {openInvoice && (
        <div>
          <Invoice
            setOpenInvoice={setOpenInvoice}
            selectedData={invoiceData}
            partnerInformation={partnerInformation}
            partnersInvoiceTab
            openInvoice={openInvoice}
            viewType={viewType}
          />
        </div>
      )}
    </section>
  );
};

export default Invoices;
